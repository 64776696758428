import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';

const HomeComponent = ({ onLogout }) => {
  return (
    <div className="App">
      <Container>
        <Row className="text-center my-4">
          <Col>
            <h1 className="display-4 fw-bold">Welcome to Shefa Green!</h1>
            <p className="text-muted">
              Shaping the future of medical cannabis with technology and care.
            </p>
          </Col>
        </Row>

        <Row className="align-items-center my-5">
          <Col md={6}>
            <Image
              src="https://via.placeholder.com/500x300" // Замените на подходящее изображение
              alt="Cannabis Cultivation"
              fluid
              rounded
            />
          </Col>
          <Col md={6}>
            <p className="lead">
              At <strong>Shefa Green</strong>, we are at the forefront of cultivating medical
              cannabis through cutting-edge technology. Our commitment to excellence and innovation
              shapes our approach, as we harness the power of the latest advancements to nurture our plants.
            </p>
            <p>
              Embracing a fully remote-controlled process, we ensure precision and efficiency at every stage.
              Our state-of-the-art facilities are designed to optimize the growth conditions, ensuring that
              each plant receives the perfect balance of light, nutrients, and care.
            </p>
          </Col>
        </Row>

        <Row className="text-center my-4">
          <Col>
            <h2>Our Mission</h2>
            <p className="mb-4">
              Deliver the highest quality of medical cannabis, responsibly and sustainably produced.
              We believe in the power of nature, enhanced by science, to bring the best therapeutic
              experiences to our clients.
            </p>
            <Button variant="success" size="lg" href="/learn-more">
              Learn More
            </Button>
          </Col>
        </Row>

        <Row className="text-center mt-5">
          <Col>
            <Button
              variant="danger"
              size="lg"
              onClick={onLogout}
            >
              Log Out
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomeComponent;
