import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import NavbarComponent from './NavbarComponent';
import Login from './Login';
import HomeComponent from './HomeComponent';
import SensorDataComponent from './SensorDataComponent';
import AdminDashboard from './AdminDashboard';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Новое состояние для загрузки

  // Проверка токена при загрузке приложения
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const role = localStorage.getItem('userRole');
    if (token) {
      setIsAuthenticated(true);
      setUserRole(role);
    } else {
      setIsAuthenticated(false);
      setUserRole(null);
    }
    setIsLoading(false); // Завершаем загрузку
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userRole');
    setIsAuthenticated(false);
    setUserRole(null);
  };

  // Если приложение загружается, показываем индикатор
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Container className="p-3">
        <NavbarComponent isAuthenticated={isAuthenticated} userRole={userRole} onLogout={handleLogout} />
        <Routes>
          <Route
            path="/login"
            element={isAuthenticated ? <Navigate to="/" /> : <Login setIsAuthenticated={setIsAuthenticated} setUserRole={setUserRole} />}
          />
          <Route
            path="/"
            element={isAuthenticated ? <HomeComponent onLogout={handleLogout} /> : <Navigate to="/login" />}
          />
          <Route
            path="/sensor-data"
            element={isAuthenticated ? <SensorDataComponent /> : <Navigate to="/login" />}
          />
          <Route
            path="/admin"
            element={isAuthenticated && userRole === 'admin' ? <AdminDashboard /> : <Navigate to="/login" />}
          />
        </Routes>
      </Container>
    </Router>
  );
};

export default App;
