import React, { useState } from "react";
import axios from "./axiosSetup";
import { useNavigate } from "react-router-dom";
import { Container, Card, Form, Button, Row, Col, Alert, Spinner } from "react-bootstrap";

const Login = ({ setIsAuthenticated, setUserRole }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        "/api/auth/login",
        { username, password },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );

      // Сохраняем токен и роль в localStorage
      localStorage.setItem("authToken", response.data.jwt);
      localStorage.setItem("userRole", response.data.role); // Сохраняем роль пользователя

      // Устанавливаем состояние аутентификации
      setIsAuthenticated(true);
      setUserRole(response.data.role); // Устанавливаем роль

      setMessage({ type: "success", text: "Login successful!" });
      setTimeout(() => navigate("/"), 1000); // Перенаправляем на главную страницу
    } catch (error) {
      console.error("Error during login:", error);
      setMessage({
        type: "danger",
        text: "Invalid username or password. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f8f9fa" }}>
      <Container>
        <Row className="justify-content-center">
          <Col md={5}>
            <Card>
              <Card.Body>
                <h2 className="text-center mb-4">Welcome Back!</h2>

                {message && (
                  <Alert variant={message.type} onClose={() => setMessage(null)} dismissible>
                    {message.text}
                  </Alert>
                )}

                <Form onSubmit={handleLogin}>
                  <Form.Group className="mb-3" controlId="formUsername">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Button
                    type="submit"
                    className="w-100"
                    style={{ backgroundColor: "#007700", borderColor: "#005500", color: "white" }}
                  >
                    {isLoading ? <Spinner animation="border" size="sm" /> : "Log In"}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
