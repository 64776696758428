import React, { useState, useEffect } from 'react';
import axios from './axiosSetup';
import { Container, Form, Button, Table, Spinner, Alert, Row, Col } from 'react-bootstrap';

function SensorDataComponent() {
  const [deviceIds, setDeviceIds] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [sensorData, setSensorData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Fetch list of device IDs
  useEffect(() => {
    const fetchDeviceIds = async () => {
      try {
        const response = await axios.get('/api/devices/particle');
        setDeviceIds(response.data);
      } catch (error) {
        console.error('Failed to fetch device IDs:', error);
        setMessage('Failed to fetch device IDs. Please check your permissions.');
      }
    };

    fetchDeviceIds();
  }, []);

  // Fetch sensor data for the selected device ID
  useEffect(() => {
    if (selectedDeviceId) {
      const fetchSensorData = async () => {
        try {
          const response = await axios.get('/api/sensors/latest', {
            params: {
              deviceId: selectedDeviceId,
              page: currentPage,
              size: 10
            }
          });
          setSensorData(response.data.data);
          setTotalPages(response.data.totalPages);
        } catch (error) {
          console.error('Error fetching sensor data:', error);
          setMessage('Error fetching sensor data. Please try again.');
        }
      };

      fetchSensorData();
    }
  }, [selectedDeviceId, currentPage]);

  // Function to handle exporting data to Excel
  const handleExportToExcel = async () => {
    if (selectedDeviceId && startDate && endDate) {
      setIsLoading(true);
      try {
        const response = await axios.get('/api/export/excel', {
          params: {
            deviceId: selectedDeviceId,
            startDate: startDate,
            endDate: endDate
          },
          responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'sensor_data.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setMessage('Data exported successfully!');
      } catch (error) {
        console.error('Error exporting data:', error);
        setMessage('Failed to export data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    } else {
      setMessage('Please select a device and specify the date range.');
    }
  };

  // Function to handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Container className="sensor-data-component my-4">
      <h1 className="text-center mb-4">Sensor Data</h1>
      {message && (
        <Alert variant="danger" onClose={() => setMessage(null)} dismissible>
          {message}
        </Alert>
      )}
      {/* Form Section */}
      <Form className="mb-4">
        <Row className="align-items-center">
          <Col md={3}>
            <Form.Group controlId="deviceSelect">
              <Form.Label>Select Device ID</Form.Label>
              <Form.Control as="select" value={selectedDeviceId} onChange={e => setSelectedDeviceId(e.target.value)}>
                <option value="">Select Device ID</option>
                {deviceIds.map(device => (
                  <option key={device.id} value={device.id}>
                    {device.name || 'Unnamed Device'} ({device.id})
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group controlId="startDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
            </Form.Group>
          </Col>
          <Col md={3} className="d-flex align-items-end">
            <Button onClick={handleExportToExcel} className="w-100 mt-2" disabled={isLoading}>
              {isLoading ? <Spinner animation="border" size="sm" /> : 'Export to Excel'}
            </Button>
          </Col>
        </Row>
      </Form>

      {/* Table Section */}
      <Table striped bordered hover responsive className="sensor-table">
        <thead>
          <tr>
            <th>Date and Time</th>
            <th>Temperature (°C)</th>
            <th>Humidity (%)</th>
            <th>CO2 (ppm)</th>
            <th>Day Status</th>
            <th>Preset</th>
          </tr>
        </thead>
        <tbody>
          {sensorData && sensorData.length > 0 ? (
            sensorData.map(item => (
              <tr key={item.timestamp}>
                <td>{new Date(item.timestamp * 1000).toLocaleString()}</td>
                <td>{item.temperature ? item.temperature.toFixed(1) : 'N/A'}</td>
                <td>{item.humidity ? item.humidity.toFixed(1) : 'N/A'}</td>
                <td>{item.co2 ? item.co2 : 'N/A'}</td>
                <td>{item.day_status || 'N/A'}</td>
                <td>{item.preset || 'N/A'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No data available</td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Pagination */}
      <div className="pagination d-flex justify-content-between mb-4">
        <Button onClick={() => handlePageChange(Math.max(currentPage - 1, 0))} disabled={currentPage === 0}>
          Previous
        </Button>
        <Button onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages - 1))} disabled={currentPage >= totalPages - 1}>
          Next
        </Button>
      </div>
    </Container>
  );
}

export default SensorDataComponent;
