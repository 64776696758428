import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NavbarComponent = ({ isAuthenticated, onLogout }) => {
  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand as={Link} to="/">Shefa Green</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          {isAuthenticated && (
            <>
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/sensor-data">Sensor Data</Nav.Link>
            </>
          )}
        </Nav>
        {isAuthenticated ? (
          <Button variant="outline-danger" onClick={onLogout}>
            Log Out
          </Button>
        ) : (
          <Nav.Link as={Link} to="/login">Login</Nav.Link>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
