import axios from 'axios';

// Установка базового URL для всех запросов
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Настройка интерсепторов запросов
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken"); // Получаем токен из localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Добавляем токен в заголовок Authorization
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Настройка интерсепторов ответов
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.error("Unauthorized access - possibly invalid token");
      // Удаление токена и редирект на страницу логина
      localStorage.removeItem("authToken");
      window.location.href = "/login"; // Укажите путь при необходимости
    }
    return Promise.reject(error);
  }
);

export default axios;
